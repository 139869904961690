import React from "react"
import {graphql} from "gatsby"
import MainLayout from "Layout/layout"
import TagList from "Layout/Lists/TagList/TagList"

const IndexList = ({
  data,
  location
}) => {

  const {projects, page} = data

  return (
    <MainLayout location={location}>
      <TagList items={projects.edges} headline={page.title} />
    </MainLayout>
  )
}

export const query = graphql`
  query indexListPageQuery {
    page: wordpressPage(wordpress_id: {eq: 113}) {
      title
      slug
    }
    projects: allWordpressWpProjects {
      edges {
        node {
          slug
          title
          categories_names {
            name
            taxonomy
          }
        }
      }
    }
  }
`

export default IndexList
